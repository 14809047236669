<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            ref="list"
            :expandRowByClick="true"
            @expandedRowsChange="handleExpandedRowsChange($event)"
            :expandedRowRender="true"
            :submit_preprocessing="submit_preprocessing"
			:slot_table_list="['operation']"
			rowKey="id"
        >
			<template slot="left_btn">
				<a-button type="primary" class="a_btn" @click="line_setting" v-if="this.$store.state.page_auth.setting">线路设置</a-button>
			</template>
			<template slot="right_btn">
				<a :href="'/manage/crm.export/lineExport' | export_form" target="_blank">
					<a-button type="primary" class="a_btn">导出</a-button>
				</a>
            </template>
			<template slot="operation" slot-scope="record">
				<a v-auth="'batch'" @click.stop="transfer(record.record)">转移</a>
            </template>
            <template slot="expandedRowRender" slot-scope="data">
                <a-table
                    :columns="columns_sub"
                    :pagination="false"
                    :dataSource="data.record.list"
                    style="margin: 0"
                >
                    <template slot="operation" slot-scope="record">
                        <a @click="see(record)">查看</a>
						<a-divider v-auth="'batch'" type="vertical" />
						<a v-auth="'batch'" @click="transfer(record)">转移</a>
						<a-divider v-auth="'delete'" type="vertical" />
						<a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(record)">
							<a>删除</a>
						</a-popconfirm>
                    </template>
                </a-table>
            </template>
        </TableList>
        <PopTableList
            rowKey="id"
            ref="pop_table_list"
            :is_created_get_list="false"
            :columns="columns_pop"
            :get_table_list="get_table_list_pop"
            :form_data_seo="form_data_seo_pop"
            :visible.sync="visible"
            :slot_table_list="['address']"
            @submit="pop_table_submit"
        >
            <template
                slot="address"
                slot-scope="data"
            >{{data.record.province + data.record.city + data.record.district + data.record.address}}</template>

			
        </PopTableList>
		<!-- 选择用户 -->
        <SelectUser @submit="submit" :visible.sync="user_visible"></SelectUser>
		<a-modal title="线路设置" :visible="line_set_visible" :width="'500px'"  @ok="line_set_submit" @cancel="line_set_visible = false" >
			
			<a-form-model-item >
				<div class="line_set_pop">
					<span class="label">最大线路数量:</span>
					<a-input v-model:value="line_num" placeholder="请输入最大线路数量" type="number"/>
				</div>
			</a-form-model-item>
		</a-modal>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { lineList, lineInfo, lookCustomer, lineTransfer,removeLine,lineExport,getLineNum,setLineNum} from "@/api/customer";
import TableList from "@/components/TableList";
import PopTableList from "@/components/PopTableList";
import SelectUser from "@/components/SelectUser";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "岗位",
        dataIndex: "role",
    },
    {
        title: "客户数",
        dataIndex: "num",
	},
	{
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

const columns_sub = [
    {
        title: "线路名称",
        dataIndex: "name",
    },
    {
        title: "客户数",
        dataIndex: "customer_num",
    },
    {
        title: "备注",
        dataIndex: "remark",

    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

const columns_pop = [
    {
        title: "客户ID",
        dataIndex: "id",
    },
    {
        title: "客户名称",
        dataIndex: "name",
    },
    {
        title: "客户地址",
        scopedSlots: {
            customRender: "address"
        }
    },
    {
        title: "业务员",
        dataIndex: "username",

    }
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
		PopTableList,
		SelectUser
    },
    data() {
        return {
			line_set_visible:false,
			line_num:0,
            get_table_list: lineList,
            get_table_list_pop: lookCustomer,
            columns,
            columns_sub,
            columns_pop,
			visible: false,
			user_visible:false,
			line_info:{},
            submit_preprocessing: {
                array_to_string: ['department','role']
            },
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    // {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "staff",
                        title: "姓名",
                        placeholder: '姓名/手机号/工号/账号',
                        options: {

                        }
					},
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data_seo_pop: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "name",
                        title: "客户",
                        placeholder: '客户名称/ID/地址',
                        options: {

                        }
                    },
                ],
            },
            form_data: {}
        };
    },
    created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
        });
        // this.$method.get_role().then(res => {
        //     this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'role', 'list', res)
        // });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});
		this.get_line_num();
    },
    methods: {
		// 线路设置弹窗的表单提交
		line_set_submit(){
			setLineNum({
				data:{
					num:this.line_num
				},
				info:true
			}).then(res=>{
				this.line_set_visible = false;
			})
		},
		// 打开线路设置弹窗
		line_setting(){
			this.line_set_visible = true;
		},
		// 获取用户上次设置的最大线路数量
		get_line_num(){
			getLineNum().then(res=>{
				this.line_num = res.data.data;
			})
		},
        see(record) {

            this.visible = true;

            let data = {
                id: record.id,
                uid: record.uid
			}
			
			this.$nextTick(res => {
				this.$refs.pop_table_list.set_data('fixed_seo_data', data)
				this.$refs.pop_table_list.get_list();
			})
			
            this.form_data_seo_pop.list.forEach(item => {
                item.options.initialValue = '';
            })
		},
		
		transfer(record){
			this.user_visible = true;
			this.line_info = record;
		},
		submit({data}){
			this.transfer_submit(data.id)
		},
        del(data) {
            removeLine({
                data: {
                    id: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
		transfer_submit(receiver){
			let data = {
				user_id:this.line_info.id,
				receiver,
			}
			let type = 'user';
			if(this.line_info.uid){
				data['line_id'] = this.line_info.id;
				data['user_id'] = this.line_info.uid;
				type = 'line';
			}else{
				data['line_id'] = -1;
			}

			this.user_visible = false;
			
			lineTransfer({
				data,
				info: true
			}).then(res=>{
				if(type == 'line'){

					let list = this.$refs.list.list;
					for(let i = 0; i < list.length; i++){
						let item = list[i];
						if(Array.isArray(item.list) && item.list.length > 0){
							for(let j = 0;  j < item.list.length; j++){
								if(data['line_id'] == item.list[j].id){
									this.get_info(i);
									return false;
								}
							}
						}
					}
				}else{
					this.$refs.list.get_list();
				}
				
			}).catch(res=>{
				
			})
		},

        pop_table_submit() {
            this.visible = false;
		},
		
		get_info(id){
			lineInfo({
				data: {
					id,
				}
			}).then(res => {
				let list = [];
				this.$refs.list.list.forEach((vo, index) => {
					if (vo.id == id) {
						vo.list = res.data.list;
					}
					list.push(vo)
				})
				this.$refs.list.set_data('list', list)
			})
		},
        async handleExpandedRowsChange(e) {
            e.forEach(item => {
				let index = this.$refs.list.list.findIndex(v=>{
					return v.id == item
				})
                if (!this.$refs.list.list[index].list) {
                    this.get_info(item)
                }
            })
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>